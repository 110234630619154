import React, { useEffect, useState, useRef } from 'react';
import queryString from "query-string";
import moment from "moment";

function LobbyMonitor(props) {

  const [queueItems, setQueueItems] = useState([]);
  const [enteredItems, setEnteredItems] = useState({});
  const [isLobbyEnabled, setIsLobbyEnabled] = useState(true);
  const [isSwitching, setIsSwitching] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const updatesCheckTimer = useRef(null);

  useEffect(() => {

    startUpdatesCheck();

  }, [])

  const getLatestQueueData = () => {

    let params = queryString.parse(props.location.search)
    var token = params["token"];

    if (!token) {
      return;
    }

    fetch(`${config.lobbyapi.url}/queue/get?token=${token}`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    }).then((response) => {
        return response.json();
    }).then(data => {

      if (data.status == 'ok') {

        var allVisitors = data.entered;
        var queue = data.queue;

        for (var item in queue) {
          const visitor = queue[item];
          if (allVisitors[visitor]) {
            queue[item] = allVisitors[visitor];
          }
        }

        setIsLobbyEnabled(data.isLobbyEnabled);
        setIsSwitching(false);

        setQueueItems(queue);
        setEnteredItems(allVisitors);
        setIsLoaded(true);

      }

    }).catch(() => {

    });

  }

  const startUpdatesCheck = () => {

    if (updatesCheckTimer.current) {
      return;
    }

    updatesCheckTimer.current = setInterval(() => {
      getLatestQueueData();
    }, 2000);

  }

  const renderItem = (item) => {

    return <div className={"item"}>
        <div><strong>Waiting id:</strong> {item.waitingId}</div>
        {item.status == 'entered' ?
          <div>
            <strong>Entered on: </strong>
            {moment(item.enteredOn).format("YYYY-MM-DD HH:mm:ss")} ({moment().diff(moment(item.enteredOn), "second")}s)
          </div> :
          <div>
            <strong>Added on: </strong>
            {moment(item.addedOn).format("YYYY-MM-DD HH:mm:ss")} ({moment().diff(moment(item.addedOn), "second")}s)
          </div>}
        <div><strong>Ping:</strong> {moment(item.lastCheck).format("YYYY-MM-DD HH:mm:ss")} ({moment().diff(moment(item.lastCheck), "second")}s)</div>
    </div>

  }

  const toggleLobby = () => {

    if (!isLoaded || isSwitching) {
      return;
    }

    let params = queryString.parse(props.location.search)
    var token = params["token"];

    if (!token) {
      return;
    }

    setIsSwitching(true);

    fetch(`${config.lobbyapi.url}/queue/toggleLobby?token=${token}&enabled=${isLobbyEnabled ? 'no' : 'yes'}`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    }).then((response) => {
      setIsSwitching(true);
    });

  }

  var toggleValue = isLoaded ? (isLobbyEnabled ? 'Yes' : 'No') : 'Loading...';
  if (isSwitching) {
    toggleValue = 'Switching...';
  }

  return (
    <div className={"mainMonitorContainer"}>

      <div className={"configContainer"}>
        <div>Is lobby enabled:</div>
        <button onClick={() => { toggleLobby(); }} value={1} disabled={isSwitching || !isLoaded ? "disabled" : ""}>{toggleValue}</button>
      </div>

      <div className={"innerContainer"}>
        <div className={"queueRow"}>
          <div className={"header"}>Waiting queue ({queueItems.length})</div>
          <div className={"items"}>
            {queueItems.length > 0 ? queueItems.map((item) => {
              return renderItem(item);
            }) : (isLoaded ? <div className={"itemsEmpty"}>Waiting queue is empty</div> : null)}
          </div>
        </div>
        <div className={"queueRow"}>
          <div className={"header"}>Entered visitors ({Object.keys(enteredItems).length - queueItems.length})</div>
          <div className={"items"}>
            {Object.keys(enteredItems).length > 0 ? Object.keys(enteredItems).map((key) => {
              const item = enteredItems[key];
              if (item.status == 'waiting') {
                return null;
              }
              return renderItem(item);
            }) : (isLoaded ? <div className={"itemsEmpty"}>No entered visitors</div> : null)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LobbyMonitor;