import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'cookies-js'
import queryString from 'query-string';
import Loader from "react-loader"

function Lobby(props) {

    const history = useHistory();
    const gateTimer = useRef(null);
    const isProcessing = useRef(false);

    useEffect(() => {
        addToGateQueue();
    }, [])

    const startGateTimer = () => {

        if (gateTimer.current) {
            return;
        }

        gateTimer.current = setInterval(() => {
            checkForNewStatus();
        }, config.main.checkForNewStateAfter);

    }

    const getRedirectUrl = () => {

      let params = queryString.parse(props.location.search)
      var data = params["data"];

      if (!data) {
        return null;
      }

      data = JSON.parse(atob((data)));

      if (!data.url) {
        return null;
      }

      return data.url;

    }

    const checkForNewStatus = () => {

        if (isProcessing.current) {
            return;
        }

        isProcessing.current = true;

        const wid = getWaitingId();

        fetch(`${config.lobbyapi.url}/queue/check`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            wid: wid,
          }),
        })
          .then((response) => {
              isProcessing.current = false;
              return response.json();
          })
          .then(data => {

            if (data && data.status) {

              const status = data.status;

              switch (status) {
                case "notfound":
                  addToGateQueue();
                  break;
                case "waiting":
                  break;
                case "entered":
                  proceedEntering();
                  break;
              }

            } else {
              window.reload();
            }

            console.log('Data for check', data);

          }).catch(() => {

        });

    }

    const setWaitingId = (wid) => {
      Cookies.expire(`${config.cookie.name}_ok`);
      Cookies.set(`${config.cookie.name}`, wid, { expires: 31622400, domain: config.cookie.domain });
    }

  const getWaitingId = () => {
    const wid = Cookies.get(`${config.cookie.name}`);

    if (!wid) {
      return null;
    }

    return wid;

  }

  const proceedEntering = () => {

      destroyCheckTimer();

      const url = getRedirectUrl();
      Cookies.set(`${config.cookie.name}_ok`, 1, {expires: 60 * 2, domain: config.cookie.domain });
      document.location.href = url;
  }

  const destroyCheckTimer = () => {

    if (gateTimer.current) {
      clearInterval(gateTimer.current);
      gateTimer.current = null;
    }

  }

  const addToGateQueue = () => {

      const wid = getWaitingId();

      destroyCheckTimer();

      const redirectUrl = getRedirectUrl();

      if (!redirectUrl) {
        console.log('No redirect url set');
        return;
      }

      fetch(`${config.lobbyapi.url}/queue/add`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          wid: wid,
        }),
      })
        .then((response) => response.json())
        .then(data => {

            if (data && data.status == 'enter') {
                setWaitingId(data.waitingId);
                proceedEntering();
            } else if (data && data.waitingId) {
                setWaitingId(data.waitingId);
                startGateTimer();
            } else {
              setTimeout(() => {
                addToGateQueue();
              }, 3000);
            }
        }).catch(() => {

        setTimeout(() => {
          addToGateQueue();
        }, 3000);

      });
  }


    return (
        <div className={"mainContainer"}>
          <Loader loaded={false} options={{"position": "relative"}} />
          <span style={{ textAlign: "center" }}>
            Please wait while you will be let into the event
          </span>
        </div>
    )
}

export default Lobby;