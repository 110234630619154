import React, {useState, useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';
import history from './Core/Navigation/History';
import Routes from './Core/Navigation/Routes';
import { useDispatch } from "react-redux";
import _, { result } from 'lodash'
import Cookies from 'cookies-js';

function App() {

    return <BrowserRouter history={history}>
        <Routes />
      </BrowserRouter>
}
export default App;