import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Lobby from '../../../Screens/Lobby';
import LobbyMonitor from '../../../Screens/Lobby/monitor';

export default function Routes() {
    return (
        <Switch>
            <Route path="/lobby" exact component={Lobby} />
            <Route path="/lobby/monitor" exact component={LobbyMonitor} />
        </Switch>
    );
}